import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/prestation',
    name: 'Prestation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prestation" */ '../views/Prestation.vue')
  },
  {
    path: '/lieux',
    name: 'Lieux',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lieux" */ '../views/Lieux.vue')
  },
  {
    path: '/tarifs',
    name: 'Tarifs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tarifs" */ '../views/Tarifs.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass:'active-route',
  scrollBehavior(to, from, savedPosition){
    return savedPosition || new Promise((resolve => {
      setTimeout(()=> resolve({top: 0, behavior :'smooth'}), 100)
    }))
  },
  routes
})

export default router
