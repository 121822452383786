<template>
  <div>
    <div class="home transition-all duration-700 h-[750px]">
      <div class="relative h-full">
        <div class="bg-black opacity-75 h-full w-full text-white flex flex-col justify-center items-center space-y-8 p-4">
          <h1 class="text-5xl max-w-screen-sm">Méditerranée Fishing</h1>
          <h2 class="text-2xl"><strong class="font-normal">Jean-Christophe Rison, guide de pêche en mer</strong></h2>
        </div>
      </div>
    </div>
    <main class="relative bg-wave">
      <section class="p-4 max-w-screen-xl mx-auto z-50">
        <div class="flex flex-col items-center justify-center space-y-20">
          <div class="my-10 space-y-10">
            <p class=" tracking-wide leading-8 ">Je suis guide de pêche professionnel depuis 2010 en Méditerranée et plus particulièrement dans la région de Marseille. Je guide aussi depuis 4 ans sur le Lac d’Esparron de Verdon à la recherche des brochets records. Je propose aussi des stages de pêche sur plusieurs jours en Guadeloupe tout inclus. Vous pouvez aller voir 
              <a href="https://www.youtube.com/channel/UCubfLc4tgjjGpp81nJYGofg" target="_blank" rel="nooponer" class="underline decoration-2 decoration-blue-400 inline">ma chaîne YOUTUBE
                <img src="@/assets/youtube-logo-png-2074.png" alt="youtube" class="text-red-600 h-8 w-8 ml-2 inline object-cover">
              </a> «méditerranée-fishing guide de pêche» afin de regarder mes vidéos sur la pêche en Guadeloupe.
            </p>
            <a href="tel:0661183009" data-aos="fade-up" class="rounded-lg p-4 bg-blue-800 text-white block lg:text-xl max-w-[580px] mx-auto">N’hésitez pas à me contacter pour plus d’informations</a>
          </div>
          <div class="my-10 space-y-20">
            <h2 class="text-3xl underline decoration-4 decoration-blue-400">Ma philosophie </h2>
            <ul class="space-y-10 my-4">
              <li data-aos="fade-up" class="font-semibold">Un bon accueil</li>
              <li data-aos="fade-up" class="font-semibold">Une transmission de mon expérience et de mes compétences en tant que guide de pêche</li>
              <li data-aos="fade-up" class="font-semibold">Une sensibilisation sur la protection de l’environnement marin</li>
              <li data-aos="fade-up" class="font-semibold">Une exigence élevée au niveau de la sécurité pour chaque sortie pêche</li>
            </ul>
            <p class="my-8 tracking-wide leading-8">J’accueille les groupes, les CE ainsi que toutes les personnes (seule ou en binôme) qui ont le souhait de passer une bonne journée en ma compagnie. Le but recherché est de m’adapter au mieux afin de répondre à vos besoins et vos envies. En mer nous naviguerons sur un bâteau homologué pour la pêche en milieu hauturier, équipé pour tous les types de pêche. Sur le Lac d’Esparron nous aurons un bâteau équipé d’un moteur électrique de dernière génération, avec tout l’équipement permettant de pratiquer la pêche sportive aux leurres. Les journées de pêche durent en général 8 à 10 heures (navigation incluse) avec une pause déjeuner.</p>
          </div>
        </div>

        
      </section>
    </main>
    <footer class="max-w-screen-xl mx-auto flex flex-col justify-center items-center space-y-20 my-20 p-4">
      <h2 class="text-3xl underline decoration-4 decoration-blue-400">A propos de moi</h2>
      <p class="my-4 tracking-wide leading-8">Je suis titulaire du BPJEPS pêche de loisir, ainsi que de l’UCC complémentaire pêche en milieu maritime. Je suis également titulaire de l’ensemble des unités de capitalisation du capitaine 200. Je suis affilié au syndicat des moniteurs guides de l’Ecole de Pêche Française, qui regroupe plusieurs dizaines de guides de pêche qui évoluent sur tout le territoire français. Mes bâteaux sont assurés à titre professionnel auprès de la compagnie d’assurance APRIL, et mon assurance responsabilité civil a été contracté auprès des partenaires du SMGPF. Les bâteaux sont super bien équipés pour la pêche, et ils ont tout le matériel nécessaire concernant la sécurité des stagiaires.</p>
      <div class="flex flex-col justify-center items-center space-y-8">
        <img data-aos="fade-up" src="../assets/Jean-christophe.jpg" alt="Jean-Christophe" class="rounded-full ring-2 ring-blue-400 p-1">
        <a data-aos="fade-up" href="tel:0661183009">Tél. : 06.61.18.30.09</a>
        <a data-aos="fade-up" href="mailto:benny.rison@laposte.net">Mail : benny.rison@laposte.net</a>
        <p data-aos="fade-up">Pour les paiements : Virement | Chèque | Espèce <br>(Pas de carte bancaire)</p>
      </div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data(){
    return {
     
    }
  },
  components: {

  },
  methods: {
  },
  mounted(){
  }
}
</script>

<style lang="scss" scoped>
// .height-screen {
//   height: calc(100vh-80px);
// }
.home {
  background-image: url('../assets/fod.jpg');
  background-position: 40% 60%;
}
</style>
