<template>
    <footer class="bg-blue-200 px-4 py-8">
        <h2 class="text-3xl underline decoration-4 decoration-blue-400 my-4">Galerie</h2>
        <div>
            <h3 class="text-2xl my-4">Thon</h3>
            <div class="flex flex-wrap justify-center items-center">
                <img src="../assets/photosthon/1.jpg" alt="thon 1" class="aspect-auto h-60 object-center">
                <img src="../assets/photosthon/2.png" alt="thon 2" class="aspect-auto h-60 object-center">
                <img src="../assets/photosthon/3.jpg" alt="thon 3" class="aspect-auto h-60 object-center">
                <img src="../assets/photosthon/4.png" alt="thon 4" class="aspect-auto h-60 object-center">
                <img src="../assets/photosthon/5.jpg" alt="thon 5" class="aspect-auto h-60 object-center">
            </div>
        </div>
        <div>
            <h3 class="text-2xl my-4">Poissons Guadeloupe</h3>
            <div class="flex flex-wrap justify-center items-center">
                <img src="../assets/photospoissonsguadeloupe/1.jpg" alt="poisson gouadeloupe 1" class="aspect-auto h-60 object-center">
                <img src="../assets/photospoissonsguadeloupe/2.jpg" alt="poisson gouadeloupe 2" class="aspect-auto h-60 object-center">
                <img src="../assets/photospoissonsguadeloupe/3.jpg" alt="poisson gouadeloupe 3" class="aspect-auto h-60 object-center">
                <img src="../assets/photospoissonsguadeloupe/4.jpg" alt="poisson gouadeloupe 4" class="aspect-auto h-60 object-center">
                <img src="../assets/photospoissonsguadeloupe/5.png" alt="poisson gouadeloupe 5" class="aspect-auto h-60 object-center">
            </div>
        </div>
        <div>
            <h3 class="text-2xl my-4">Loup</h3>
            <div class="flex flex-wrap justify-center items-center">
                <img src="../assets/photosloup/1.jpg" alt="Loup 1" class="aspect-auto h-60 object-center">
                <img src="../assets/photosloup/2.jpg" alt="Loup 2" class="aspect-auto h-60 object-center">
                <img src="../assets/photosloup/3.jpg" alt="Loup 3" class="aspect-auto h-60 object-center">
                <img src="../assets/photosloup/4.jpg" alt="Loup 4" class="aspect-auto h-60 object-center">
                <img src="../assets/photosloup/5.jpg" alt="Loup 5" class="aspect-auto h-60 object-center">
                <img src="../assets/photosloup/6.jpg" alt="Loup 5" class="aspect-auto h-60 object-center">
                <img src="../assets/photosloup/7.jpg" alt="Loup 5" class="aspect-auto h-60 object-center">
                <img src="../assets/photosloup/8.jpg" alt="Loup 5" class="aspect-auto h-60 object-center">
            </div>
        </div>
        <div>
            <h3 class="text-2xl my-4">Liche</h3>
            <div class="flex flex-wrap justify-center items-center">
                <img src="../assets/photosliche/1.jpg" alt="Liche 1" class="aspect-auto h-60 object-center">
                <img src="../assets/photosliche/2.jpg" alt="Liche 2" class="aspect-auto h-60 object-center">
                <img src="../assets/photosliche/3.jpg" alt="Liche 3" class="aspect-auto h-60 object-center">
                <img src="../assets/photosliche/4.jpg" alt="Liche 4" class="aspect-auto h-60 object-center">
                <img src="../assets/photosliche/5.jpg" alt="Liche 5" class="aspect-auto h-60 object-center">
                <img src="../assets/photosliche/6.jpg" alt="Liche 6" class="aspect-auto h-60 object-center">
                <img src="../assets/photosliche/7.jpg" alt="Liche 7" class="aspect-auto h-60 object-center">
            </div>
        </div>
        <div>
            <h3 class="text-2xl my-4">Daurades</h3>
            <div class="flex flex-wrap justify-center items-center">
                <img src="../assets/photosdaurades/1.jpg" alt="Daurade 1" class="aspect-auto h-60 object-center">
                <img src="../assets/photosdaurades/2.jpg" alt="Daurade 2" class="aspect-auto h-60 object-center">
                <img src="../assets/photosdaurades/3.jpg" alt="Daurade 3" class="aspect-auto h-60 object-center">
                <img src="../assets/photosdaurades/4.jpg" alt="Daurade 4" class="aspect-auto h-60 object-center">
                <img src="../assets/photosdaurades/5.jpg" alt="Daurade 5" class="aspect-auto h-60 object-center">
                <img src="../assets/photosdaurades/6.jpg" alt="Daurade 6" class="aspect-auto h-60 object-center">
                <img src="../assets/photosdaurades/7.jpg" alt="Daurade 7" class="aspect-auto h-60 object-center">
                <img src="../assets/photosdaurades/8.jpg" alt="Daurade 8" class="aspect-auto h-60 object-center">
            </div>
        </div>
        <div>
            <h3 class="text-2xl my-4">Brochets</h3>
            <div class="flex flex-wrap justify-center items-center">
                <img src="../assets/photosbrochets/1.jpg" alt="Brochet 1" class="aspect-auto h-60 object-center">
                <img src="../assets/photosbrochets/2.jpg" alt="Brochet 2" class="aspect-auto h-60 object-center">
                <img src="../assets/photosbrochets/3.jpg" alt="Brochet 3" class="aspect-auto h-60 object-center">
                <img src="../assets/photosbrochets/4.jpg" alt="Brochet 4" class="aspect-auto h-60 object-center">
                <img src="../assets/photosbrochets/5.jpg" alt="Brochet 5" class="aspect-auto h-60 object-center">
                <img src="../assets/photosbrochets/6.jpg" alt="Brochet 6" class="aspect-auto h-60 object-center">
            </div>
        </div>
        <div>
            <h3 class="text-2xl my-4">Autres</h3>
            <div class="flex flex-wrap justify-center items-center">
                <img src="../assets/photomultiespeces/1.png" alt="poisson 1" class="aspect-auto h-60 object-center">
                <img src="../assets/photomultiespeces/2.jpg" alt="poisson 2" class="aspect-auto h-60 object-center">
                <img src="../assets/photomultiespeces/3.jpg" alt="poisson 3" class="aspect-auto h-60 object-center">
                <img src="../assets/photomultiespeces/4.jpg" alt="poisson 4" class="aspect-auto h-60 object-center">
                <img src="../assets/photomultiespeces/5.jpg" alt="poisson 5" class="aspect-auto h-60 object-center">
                <img src="../assets/photomultiespeces/6.jpg" alt="poisson 6" class="aspect-auto h-60 object-center">
                <img src="../assets/photomultiespeces/7.jpg" alt="poisson 7" class="aspect-auto h-60 object-center">
                <img src="../assets/photomultiespeces/8.jpg" alt="poisson 8" class="aspect-auto h-60 object-center">
                <img src="../assets/photomultiespeces/9.jpg" alt="poisson 9" class="aspect-auto h-60 object-center">
                <img src="../assets/photomultiespeces/10.jpg" alt="poisson 10" class="aspect-auto h-60 object-center">
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name:'Footer',
    data(){
        return {

        }
    },
    methods: {

    }
}
</script>

<style>

</style>