<template>
    <div :class="[onHome ? ' bg-transparent' : 'bg-blue-300', isScrolling ? 'bg-blue-300': 'bg-transparent text-white']" class="top-0 h-20 flex items-center fixed w-full z-50 transition-all duration-500">
        <nav class="max-w-screen-xl mx-auto">
            <ul class="list-none flex space-x-8 lg:space-x-60 justify-center items-center">
                <li>
                    <router-link @click="checkHome()" class="router-link" to="/">Accueil</router-link>
                </li>
                <li>
                    <router-link @click="checkHome()" class="router-link" to="/prestation">Prestations</router-link>
                </li>
                <li>
                    <router-link @click="checkHome()" class="router-link" to="/lieux">Lieux de pêche</router-link>
                </li>
                <li>
                    <router-link @click="checkHome()" class="router-link" to="/tarifs">Tarif</router-link>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name:'Header',
    data(){
        return {
            isScrolling:false,
            onHome:true,
        }
    },
    methods:{
        isUserScrolling(){
            window.addEventListener('scroll',()=>{
                if(this.$route.name == 'Home'){
                    if(window.scrollY > 150){
                        this.isScrolling= true;
                    }
                    if(window.scrollY < 150) {
                        this.isScrolling= false;
                    }
                }
            })
        },
        checkHome(){
            setTimeout(() => {
                if(this.$route.name === 'Home'){
                    this.onHome = true;
                console.log('on home');
                } else {
                    console.log('not home');
                    this.onHome = false;
                }
            }, 100);
        }
    },
    mounted(){
        this.isUserScrolling();
    },
}
</script>

<style lang="scss">
.router-link {
    position:relative;
    &:after {
        content:'';
        position:absolute;
        bottom: -5px;
        left: 0;
        transform-origin: right;
        height: 3px;
        width: 100%;
        transform:scaleX(0);
        background-color:white;
        transition: all 0.3s ease-in-out;
    }
    &:hover {
        &:after {
            content: '';
            position: absolute;
            transform:scaleX(1);
        }
    }
}
</style>