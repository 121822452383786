<template>
  <div class="overflow-x-hidden">
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components:{
    Header, Footer,
  },
  mounted(){
    AOS.init();
  }

}
</script>


<style lang="scss">
#app {
  font-family: 'Roboto',Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html {
  background-color: rgb(235, 235, 235);
}
@font-face {
  font-family: "Roboto";
  src: url('./assets/fonts/Roboto-Medium.ttf');
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.active-route {
  position:relative;
    &:after {
      content:'';
      position:absolute;
      bottom: -5px;
      left: 0;
      transform-origin: right;
      height: 3px;
      width: 100%;
      transform:scaleX(1);
      background-color:white;
      transition: all 0.3s ease-in-out;
    }
}
</style>
